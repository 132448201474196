import Timeline from 'components/Timeline';
import styles from './styles.module.scss';

const Journey = () => {
  return (
    <>
      <h2 id='journey' className='section-title'>
        Journey
      </h2>
      <div className={styles.journey}>
        <Timeline />
      </div>
    </>
  );
};

export default Journey;
