const projects = [
  {
    image: 'https://via.placeholder.com/500x600',
    title: 'World Graph',
    description:
      'A web application that uses advanced Graph Algorithms to find the Shortest Path between two points on the World Map. User can also visualize the algorithm in action.',
    tags: ['react', 'nodejs', 'typescript', 'graphs', 'docker'],
    liveDemoLink: 'https://world-graph.herokuapp.com',
    videoDemoLink: '',
    codeLink: '#',
  },
  {
    image: 'https://via.placeholder.com/500x600',
    title: 'Kaisen OS',
    description:
      'A web application that mimics the working of an Operating System. It has a file system, authentication, themes and wallpapers, etc. It also has a terminal that can be used to run commands.',
    tags: ['react', 'nodejs', 'typescript', 'firebase', 'mongodb', 'websockets'],
    liveDemoLink: 'https://kaisen-os.herokuapp.com',
    videoDemoLink: '',
    codeLink: '#',
  },
  {
    image: 'https://via.placeholder.com/500x600',
    title: 'Quizzio',
    description:
      'A web application to host contests and quizzes. It has authentication, private and public exams, online automated judge, ranking, multiple question types etc.',
    tags: ['react', 'nodejs', 'typescript', 'prisma', 'mysql'],
    liveDemoLink: 'https://quizzio.vercel.app',
    videoDemoLink: '',
    codeLink: '#',
  },
];

export default projects;
