import { useEffect, useRef, useState } from 'react';
import { typeText } from 'utilities/functions';
import styles from './styles.module.scss';

const Home = () => {
  const titleRef = useRef(null);
  const [title, setTitle] = useState('');
  useEffect(() => {
    if (titleRef) {
      typeText('Bihan Chakraborty', 100, (char) => setTitle((prev) => prev + char));
    }
  }, [titleRef]);
  return (
    <div id='hero' className={styles.heroContainer}>
      <div ref={titleRef} className={styles.heroTitle}>
        {title}
        <span>&nbsp;</span>
      </div>
      <div className={styles.heroDescription}>
        I'm a Developer with an effective mindset of learning and implementing modern technologies. My domain of expertise is mainly in the field of Web
        Development. Beside development, I enjoy practicing competitive programming. I also enjoy cooking 🍳 and travelling 🚅 in my free time.
      </div>
    </div>
  );
};

export default Home;
