import contact from 'layouts/Contact/data';
import projectsData from 'layouts/Projects/data';
import skills from 'layouts/Skills/data';
import { formatJsonToHtml } from 'utilities/functions';

const commands = {
  help: getHelpResults(),
  greet: `"Hello Visiter! Hope you are doing well! Type help for more info."`,
  resume: getResume(),
  projects: processProjects(projectsData),
  skills: formatJsonToHtml(skills),
  contact: formatJsonToHtml(contact),
};

function getResume() {
  return formatJsonToHtml({
    resumeLink: '<a target=_blank href=https://bit.ly/resume-bihan>https://bit.ly/resume-bihan</a>',
  });
}

function getHelpResults() {
  return formatJsonToHtml({
    greet: 'show greeting message',
    clear: 'clear the terminal',
    help: 'show this help',
    resume: 'show my resume',
    skills: 'show my skills',
    projects: 'show my projects',
    contact: 'show my contact info',
  });
}

function processProjects(data) {
  const projects = [];
  data.forEach((obj) => {
    let project = {
      name: obj.title,
      linkDemoLink: obj.liveDemoLink,
      videoDemoLink: obj.videoDemoLink,
      codeLink: obj.codeLink,
    };
    projects.push(project);
  });
  return formatJsonToHtml(projects);
}

export default commands;
