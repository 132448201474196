export const typeText = (str, speed = 150, onCharInsert = () => {}, onTypeComplete = () => {}) => {
  if (!str) {
    onTypeComplete();
    return;
  }
  onCharInsert(str[0]);
  setTimeout(() => {
    typeText(str.slice(1), speed, onCharInsert, onTypeComplete);
  }, speed);
};

export const formatJsonToHtml = (json) => {
  const replacables = {
    '\n': '<br>',
    ' ': '&nbsp;',
  };
  var res = JSON.stringify(json, null, 2);
  for (var key in replacables) {
    res = res.replace(new RegExp('(' + key + ')(?!([^<]+)?>)', 'gi'), replacables[key]);
  }
  return res;
};
