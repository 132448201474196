import './styles/main.scss';
import Navbar from './components/Navbar';
import Terminal from './components/Terminal';
import Contact from './layouts/Contact';
import Hero from './layouts/Hero';
import Projects from './layouts/Projects';
import Background from './layouts/Background';
import Journey from './layouts/Journey';
import Skills from './layouts/Skills';
import ScrollToTop from 'components/TopScroll';

function App() {
  return (
    <main>
      <Background />
      <Navbar />
      <div className='container-lg'>
        <section className='home-container'>
          <Hero />
        </section>
        <section className='about-container'>
          <Terminal />
        </section>
        <section className='skills-container'>
          <Skills />
        </section>
        <section className='journey-container'>
          <Journey />
        </section>
        <section className='projects-container'>
          <Projects />
        </section>
        <section className='contact-container'>
          <Contact />
        </section>
        <section className='footer'>
          <span>Made by Bihan Chakraborty © 2022</span>
        </section>
        <ScrollToTop />
      </div>
    </main>
  );
}

export default App;
