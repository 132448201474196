import { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { typeText } from 'utilities/functions';
import commands from './commands';

const Statement = ({ command, result }) => {
  return (
    <div className={styles.statement}>
      <div className={`${styles.inputStatement}`}>{command}</div>
      <div className={`${styles.returnStatement}`} dangerouslySetInnerHTML={{ __html: result }}></div>
    </div>
  );
};

const Terminal = () => {
  const [statements, setStatements] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!inputRef) return;
    setFocus();
    triggerStartingMessage();
    // eslint-disable-next-line
  }, [inputRef]);

  const triggerStartingMessage = () => {
    const addCharToInput = (c) => (inputRef.current.value += c);
    const onComplete = () => handleKeyUp({ key: 'Enter', target: { value: inputRef.current.value } });
    window.addEventListener('scroll', () => setTimeout(() => typeText('greet', 150, addCharToInput, onComplete), 10), { once: true });
  };

  const addStatement = (command) => {
    const addNewStatement = (command, result) => setStatements((s) => [...s, { command, result }]);
    if (command === 'greet') {
      addNewStatement(command, commands.greet);
    } else if (command === 'clear') {
      setStatements([]);
    } else if (command === 'help') {
      addNewStatement(command, commands.help);
    } else if (command === 'resume') {
      addNewStatement(command, commands.resume);
    } else if (command === 'projects') {
      addNewStatement(command, commands.projects);
    } else if (command === 'skills') {
      addNewStatement(command, commands.skills);
    } else if (command === 'contact') {
      addNewStatement(command, commands.contact);
    } else {
      addNewStatement(command, '"Command not found. Type help for more info."');
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      addStatement(e.target.value.trim());
      inputRef.current.value = '';
    }
  };

  const setFocus = () => inputRef.current.focus();

  return (
    <div className={styles.terminal} onClick={setFocus}>
      <div className={styles.terminalHeader}>
        <div className={`${styles.headerButton} ${styles.red}`} />
        <div className={`${styles.headerButton} ${styles.yellow}`} />
        <div className={`${styles.headerButton} ${styles.green}`} />
      </div>
      <div className={styles.terminalWindow}>
        <div>
          {statements.map((statement, i) => (
            <Statement key={i} command={statement.command} result={statement.result} />
          ))}
          <div className={styles.statement}>
            <div className={`${styles.inputStatement} text-ellipsis`}>
              <input ref={inputRef} type='text' onKeyUp={(e) => handleKeyUp(e)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminal;
