import { useState } from 'react';
import ProjectCard from 'components/ProjectCard';
import projectsData from './data';
// import styles from './styles.module.scss';

const Projects = () => {
  const [projects] = useState(projectsData);
  return (
    <>
      <h2 id='projects' className='section-title'>
        Projects
      </h2>
      {projects.map((project, i) => (
        <ProjectCard key={i} project={project} />
      ))}
    </>
  );
};

export default Projects;
