import styles from './styles.module.scss';
import { Link } from 'react-scroll';
const Navbar = () => {
  return (
    <div className={styles.navbarContainer}>
      <div className='container-lg'>
        <nav className={styles.navbar}>
          {/* <Link to='hero' smooth={true} offset={-1000} duration={500}>
            Home
          </Link> */}
          <Link to='skills' smooth={true} offset={-90} duration={500}>
            Skills
          </Link>
          <Link to='journey' smooth={true} offset={-90} duration={500}>
            Journey
          </Link>
          <Link to='projects' smooth={true} offset={-90} duration={500}>
            Projects
          </Link>
          <Link to='contact' smooth={true} offset={-90} duration={500}>
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
