import styles from './styles.module.scss';
import UpArrowIcon from 'assets/arrow_up.svg';
import { animateScroll } from 'react-scroll';
import { useEffect, useRef } from 'react';

const ScrollToTop = () => {
  const scrollEl = useRef(null);

  useEffect(() => {
    if (!scrollEl) return;
    let listener = window.addEventListener('scroll', () => (scrollEl.current.style.display = window.scrollY > 1000 ? 'block' : 'none'));
    return () => window.removeEventListener('scroll', listener);
  }, [scrollEl]);

  return <img ref={scrollEl} src={UpArrowIcon} onClick={animateScroll.scrollToTop} className={styles.topScroll} alt='Scroll to top' />;
};

export default ScrollToTop;
